import React from "react";
import Img from "../../assets/images/header_logo.png";
import Img1 from "../../assets/images/logo_title.png";
import Ribbon from "../../assets/images/ribbon.png";

const Main_Menu = () => {
  // const MenuGroup
  const SaladData = [
    {
      title: "Insalata estiva",
      desc: "Combination of vegetables, raspberry, sundried tomato vinaigrette, feta cheese",
      price: "(420)",
    },
    {
      title: "Mediterranean Salad",
      desc: "Eggplant, Peppers, olives, feta",
      price: "(420)",
    },
  ];
  const SaladData1 = [
    {
      title: "Arugula and Feta Salad",
      desc: "Orange dressing, Candid walnut, orange",
      price: "(600)",
    },
    {
      title: "Cesar Salad",
      desc: "",
      price: "(420)",
    },
  ];

  const PastaData = [
    {
      title: "(PASTA-600)",
      desc1: "penne",
      desc2: "spaghetti",
      desc3: "Rigatoni",
    },
  ];
  const PastaData1 = [
    {
      title: "(SELECTION OF SAUCES)",
      desc1: "Arrabbiata",
      desc2: "Creamy alfredo sauce",
      desc3: "Sweet basil pesto",
      desc4: "Aglio, olio peperoncin",
    },
  ];

  const SoupData = [
    {
      title: "Mushroom Consomme",
      desc: "Wild Mushrooms, Garlic Chips",
      price: "(330)",
    },
    {
      title: "Cauliflower truffle",
      desc: "Black truffle",
      price: "(330)",
    },
  ];
  const SoupData1 = [
    {
      title: "Asparagus pistachio",
      desc: "Thyme oil, mix herbs",
      price: "(330)",
    },
  ];

  const RissotoData = [
    {
      title: "Wild mushroom tortellini",
      desc: "porcini cappuccino, Parmigiano Reggiano",
      price: "(699)",
    },
    {
      title: "Artichoke wanton",
      desc: "Grill Pok choi, carrot ginger Foam",
      price: "(699)",
    },
    {
      title: "Semolina gnocchi",
      desc: "Parmesan Fondu, enoki mushroom",
      price: "(699)",
    },
  ];
  const RissotoData1 = [
    {
      title: "Mushroom Rissoto",
      desc: "Selected Mushroom, Parmigiano Reggiano",
      price: "(699)",
    },
  ];
  const SidesData = [
    {
      title: "Creamy spinach",
      price: "(222)",
    },
    {
      title: "Parmesan Mash Potatoes",
      price: "(222)",
    },
    {
      title: "Grill Asparagus",
      price: "(222)",
    },
    {
      title: "Paprika Dusted Sweet Potatoes fries",
      price: "(222)",
    },
  ];
  const DessertData = [
    {
      title: "Rabdi",
      price: "(222)",
    },
    {
      title: "Motichur Parfait",
      price: "(141)",
    },
  ];
  const DessertData1 = [
    {
      title: "Walnut Brownie with vanilla ice cream",
      price: "(222)",
    },
    {
      title: "Hazelnut Praline",
      price: "(222)",
    },
  ];
  const AppetizerData = [
    {
      title: "PCB",
      desc: "Parmesan, onion marmalade, Balsamic served with Piadina Bread.",
      price: "(330)",
    },
    {
      title: "Mushroom Crostini",
      desc: "Truffle Cream, Grana pardono, Micro green.",
      price: "(420)",
    },
    {
      title: "Burrata",
      desc: "Tomato carpaccio, fresh burrata chesse, sweet basil pesto, extra virgin olive oil.",
      price: "(789)",
    },
    {
      title: "Goat Chesse roulade",
      desc: "Saffron pears puree, hazel nuts, sweet onion jam.",
      price: "(888)",
    },
  ];
  const AppetizerData1 = [
    {
      title: "Fondu Baked Potato",
      desc: "Pesto cream, Parmesan fundu",
      price: "(420)",
    },
    {
      title: "Curry Flavoured stuffed Cottage Chesse",
      desc: "Gilantro Pesto, nuts.",
      price: "(600  )",
    },
  ];

  const BarBitesData = [
    {
      title: "Peri peri sweet potato fries",
      desc: "Peri peri French fries        (222)",
      price: "(330)",
    },
    {
      title: "Vegetable Quesadilla",
      desc: "Spicy vegetable, Cheese, salsa and Guacamole.",
      price: "(330)",
    },
    {
      title: "Mozzrella Sticks",
      desc: "Mozzrella batcons, crumb fried served with spicy Tomato sauce.",
      price: "(330)",
    },
    {
      title: "Arabic Mezze",
      desc: "Assortment of hummus, tzatziki, falafel and pita bread",
      price: "(420)",
    },
    {
      title: "Super Nacho Chips",
      desc: "Crisp Nachos served with cheese sauce, salsa and guacamole.",
      price: "(420)",
    },
    {
      title: "Mexican Taco",
      desc: "Tacos Stuffed with Mexican beans and chesse.",
      price: "(330)",
    },
    {
      title: "Peri Peri Potatoes",
      desc: "Crisp Potato wedges with melted chesse.",
      price: "(222)",
    },
  ];
  const BarBitesData1 = [
    {
      title: "Rock Corn",
      desc: "Freid corn Spicy Mayo.",
      price: "(330)",
    },
    {
      title: "Cajun Spanakopita",
      desc: "Feta corn",
      price: "(330)",
    },
    {
      title: "Vegetable Spring Roll",
      desc: "Served with Thai sweet chilli sauce.",
      price: "(330)",
    },
    {
      title: "Chilli Chesse Crostini",
      desc: "Baguette bread, Cheddar chesse, herbs.",
      price: "(330)",
    },
  ];
  return (
    <>
      <div className="relative">
        <div className="h-auto">
          <div className="bg-secondary-darkpurple-500 p-4 lg:p-8 h-auto">
            <div className="max-w-3xl mx-auto">
              <div className="bg-menu-bg-img fixed w-full h-3/4 top-36 right-0 bottom-0 left-0 bg-no-repeat bg-center bg-contain opacity-20"></div>
              <div className="flex flex-col justify-center items-center">
                <div className="">
                  <div className="flex justify-center">
                    <img src={Img} className="w-16 h-24" alt="icon" />
                  </div>
                  <div className="flex justify-center pb-6">
                    <img src={Img1} className="w-36 h-12" alt="title-icon" />
                  </div>
                </div>
                <div className="h-600">
                  <div className="min-h-full">
                    <div className=" text-4xl lg:text-6xl text-secondary-golden-500 uppercase text-center font-Abhaya">
                      Salads
                    </div>
                    <div className="flex justify-center items-center h-16">
                      <img src={Ribbon} alt="img" className="w-48"/>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-4">
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {SaladData.map((aa) => {
                            return (
                              <div className="h-full lg:h-40">
                                <div className="pb-8 min-h-full">
                                  <div className="">
                                    {aa.title}
                                    <span className="pl-2">{aa.price}</span>
                                  </div>
                                  <div className="italic">{aa.desc}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {SaladData1.map((aa) => {
                            return (
                              <div className="h-full lg:h-40">
                                <div className="pb-8 min-h-full">
                                  <div className="">
                                    {aa.title}
                                    <span className="pl-2">{aa.price}</span>
                                  </div>
                                  <div className="italic">{aa.desc}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-600 w-full">
                  <div className="min-h-full">
                    {/* <div className="max-h-full lg:max-h-96"> */}
                    <div className="w-full text-4xl lg:text-6xl text-secondary-golden-500 uppercase text-center font-Abhaya">
                      Soup
                    </div>
                    <div className="flex justify-center items-center h-16">
                      <img src={Ribbon} alt="img" className="w-48"/>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-4">
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {SoupData.map((aa) => {
                            return (
                              <div className="h-full lg:h-40">
                                <div className="pb-8 min-h-full">
                                  <div className="">
                                    {aa.title}
                                    <span className="pl-2">{aa.price}</span>
                                  </div>
                                  <div className="italic">{aa.desc}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {SoupData1.map((aa) => {
                            return (
                              <div className="h-full lg:h-40">
                                <div className="pb-8 min-h-full">
                                  <div className="">
                                    {aa.title}
                                    <span className="pl-2">{aa.price}</span>
                                  </div>
                                  <div className="italic">{aa.desc}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="h-600 w-full">
                  <div className="min-h-full">
                    {/* <div className="max-h-full lg:max-h-96"> */}
                    <div className="w-full text-4xl lg:text-6xl text-secondary-golden-500 uppercase text-center font-Abhaya">
                      Make Your Own Pasta
                    </div>
                    <div className="flex justify-center items-center h-16">
                      <img src={Ribbon} alt="img" className="w-48"/>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-4">
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {PastaData.map((aa) => {
                            return (
                              <div className="h-full lg:h-40">
                                <div className="pb-8 min-h-full">
                                  <div className="">{aa.title}</div>
                                  <div className="">{aa.desc1}</div>
                                  <div className="">{aa.desc2}</div>
                                  <div className="">{aa.desc3}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {PastaData1.map((aa) => {
                            return (
                              <div className="h-full lg:h-40">
                                <div className="pb-8 min-h-full">
                                  <div className="">{aa.title}</div>
                                  <div className="">{aa.desc1}</div>
                                  <div className="">{aa.desc2}</div>
                                  <div className="">{aa.desc3}</div>
                                  <div className="">{aa.desc4}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="h-auto lg:h-600">
                  <div className="min-h-full">
                    {/* <div className="max-h-full"> */}
                    <div className=" text-4xl lg:text-6xl text-secondary-golden-500 uppercase text-center font-Abhaya">
                      Pasta, Risotto & Gnocchi
                    </div>
                    <div className="flex justify-center items-center h-16">
                      <img src={Ribbon} alt="img" className="w-48"/>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-4">
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {RissotoData.map((aa) => {
                            return (
                              <div className="h-full lg:h-40">
                                <div className="pb-8 min-h-full">
                                  <div className="">
                                    {aa.title}
                                    <span className="pl-2">{aa.price}</span>
                                  </div>
                                  <div className="italic">{aa.desc}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {RissotoData1.map((aa) => {
                            return (
                              <div className="h-full lg:h-40">
                                <div className="pb-8 min-h-full">
                                  <div className="">
                                    {aa.title}
                                    <span className="pl-2">{aa.price}</span>
                                  </div>
                                  <div className="italic">{aa.desc}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="max-h-full lg:max-h-96 w-full">
                  <div className=" text-4xl lg:text-6xl text-secondary-golden-500 uppercase text-center font-Abhaya">
                    Sides
                  </div>
                  <div className="flex justify-center items-center h-16">
                      <img src={Ribbon} alt="img" className="w-48"/>
                    </div>
                  <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96 w-full">
                    <div className="text-2xl text-secondary-golden-500">
                      {SidesData.map((aa) => {
                        return (
                          <div className="pb-8">
                            <div className="">
                              {aa.title}
                              <span className="pl-2">{aa.price}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="h-600">
                  <div className="min-h-full">
                    {/* <div className="max-h-full lg:max-h-96"> */}
                    <div className=" text-4xl lg:text-6xl text-secondary-golden-500 uppercase text-center font-Abhaya">
                      Dessert
                    </div>
                    <div className="flex justify-center items-center h-16">
                      <img src={Ribbon} alt="img" className="w-48"/>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-4">
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {DessertData.map((aa) => {
                            return (
                              <div className="pb-8">
                                <div className="">
                                  {aa.title}
                                  <span className="pl-2">{aa.price}</span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {DessertData1.map((aa) => {
                            return (
                              <div className="pb-8 ">
                                <div className="">
                                  {aa.title}
                                  <span className="pl-2">{aa.price}</span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
                <div className="" style={{ height: 950 }}>
                  <div className="min-h-full">
                    {/* <div className="max-h-full lg:max-h-96"> */}
                    <div className=" text-4xl lg:text-6xl text-secondary-golden-500 uppercase text-center font-Abhaya">
                      Appetizers
                    </div>
                    <div className="flex justify-center items-center h-16">
                      <img src={Ribbon} alt="img" className="w-48"/>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-4">
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {AppetizerData.map((aa) => {
                            return (
                              <div className="h-full lg:h-40">
                                <div className="pb-8 min-h-full">
                                  <div className="">
                                    {aa.title}
                                    <span className="pl-2">{aa.price}</span>
                                  </div>
                                  <div className="italic">{aa.desc}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {AppetizerData1.map((aa) => {
                            return (
                              <div className="h-full lg:h-40">
                                <div className="pb-8 min-h-full">
                                  <div className="">
                                    {aa.title}
                                    <span className="pl-2">{aa.price}</span>
                                  </div>
                                  <div className="italic">{aa.desc}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="h-full lg:h-1230">
                  <div className="min-h-full">
                    {/* <div className="max-h-full lg:max-h-96"> */}
                    <div className=" text-4xl lg:text-6xl text-secondary-golden-500 uppercase text-center font-Abhaya">
                      Bar Bites
                    </div>
                    <div className="flex justify-center items-center h-16">
                      <img src={Ribbon} alt="img" className="w-48"/>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-4">
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {BarBitesData.map((aa) => {
                            return (
                              <div className="h-full lg:h-40">
                                <div className="pb-8 min-h-full">
                                  <div className="">
                                    {aa.title}
                                    <span className="pl-2">{aa.price}</span>
                                  </div>
                                  <div className="italic">{aa.desc}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="p-4 text-secondary-golden-500 font-Abhaya max-h-full lg:max-h-96">
                        <div className="text-2xl text-secondary-golden-500 max-w-md">
                          {BarBitesData1.map((aa) => {
                            return (
                              <div className="h-full lg:h-40">
                                <div className="pb-8 min-h-full">
                                  <div className="">
                                    {aa.title}
                                    <span className="pl-2">{aa.price}</span>
                                  </div>
                                  <div className="italic">{aa.desc}</div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Main_Menu;
